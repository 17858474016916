import { instance } from '../../apiBase/instance';
var authToken:any = localStorage.getItem('authToken');

export const BaseApi = {

	getSetting() {
		return instance.get<any>(`api/settings`, {});
	},
	getListNodes(param:any) {
		return instance.get<any>(`api/node-tiers`, {
			params: param
		});
	},
	getMyNodes(param:any, address:any) {
		return instance.get<any>(`/api/users/wallet/${address}/nodes`, {
			params: param
		});
	},
	getAmountTierByAccount(tier:any, address:any) {
		return instance.get<any>(`api/node-tiers/user/${address}/tier/${tier}`, {});
	},

	checkWlTierAddress(tier:any, address:any) {
		let data = {
			"address": address,
  			"tier_id": tier
		}
		return instance.post<any>(`api/whitelists/check`, data, {});
	},

	getRefCode(address:any) {
		return instance.get<any>(`api/users/wallet/${address}/ref-code`, {});
	},

	getOrderRefCode(address:any) {
		return instance.get<any>(`api/ref-codes/${address}/orders`, {});
	},

	checkRefCode(data:any) {
		return instance.post<any>(`api/ref-codes/check`, data, {});
	},
	
	getStatistics() {
		return instance.get<any>(`api/pools/statistics`, {});
	},
	getConfigApy() {
		return instance.get<any>(`api/configs/apy`, {});
	},
	getLeaderboards(param:any) {
		return instance.get<any>(`api/leaderboards`, {
			params: param
		});
	},
	getRecentJoin(param:any) {
		return instance.get<any>(`api/leaderboards/recent-joins`, {
			params: param
		});
	},
	getHistories(param:any, wallet:any) {
		return instance.get<any>(`api/claim-yields/user/${wallet}?wallet=${wallet}`, {
			params: param
		});
	},
	


	verifyRazerId(params:any) {
		return instance.get<any>(`api/razers/authorize`, {params});
	},
	checkRazerIdVerifyed(data:any) {
		return instance.post<any>(`api/users/razer-verify`, data, {});
	},
	getTokenRazerId(data:any) {
		return instance.post<any>(`api/razers/token`, data, {});
	},

	createOrderNode(data:any) {
		return instance.post<any>(`api/users/order`, data, {});
	},
	userMintLicense(data:any) {
		return instance.post<any>(`api/users/mint-license`, data, {});
	},
	
	getMyNfts(param:any, wallet:any) {
		return instance.get<any>(`api/licenses/${wallet}`, {
			params: param
		});
	},
	getMyStakingNfts(param:any, wallet:any) {
		return instance.get<any>(`api/licenses/${wallet}/my-staking-licenses`, {
			params: param
		});
	},
};


import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'



const GuardianNodes = () => {

    

    const dataSource = [
        {
            key: '1',
            address: 'Mike',
            operator: 32,
            uptime_all: 32,
            uptime_7day: 32,
            commission: 32,
            status: 32,
            received_delegations: 100,
        },
        {
            key: '2',
            address: 'Mike',
            operator: 32,
            uptime_all: 32,
            uptime_7day: 32,
            commission: 32,
            status: 32,
            received_delegations: 100,
        },
        {
            key: '3',
            address: 'Mike',
            operator: 32,
            uptime_all: 32,
            uptime_7day: 32,
            commission: 32,
            status: 32,
            received_delegations: 100,
        },
    ];

    const columns = [
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Received Delegations',
            dataIndex: 'received_delegations',
            key: 'received_delegations',
        },
        {
            title: 'Uptime (All)',
            dataIndex: 'uptime_all',
            key: 'uptime_all',
        },
        {
            title: 'Uptime (7days)',
            dataIndex: 'uptime_7day',
            key: 'uptime_7day',
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '',
            dataIndex: 'status',
            key: 'status',
        },
    ];
  
    return (
        <>
            <p className="text-gray-light mt-0">Total of <span className="text-white font-16">2533</span> Verifiers.</p>
            <div className="">
                <Table className="table-delegates" dataSource={dataSource} columns={columns} />
            </div>
        </>
    );
};
export default GuardianNodes;


import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { TabsProps } from 'antd';
import GuardianNodes from "./guardiant-node";


const Delegate = () => {

    let tab:any = localStorage.getItem('tabSlt');
    const [activeTab , setActiveTab] = useState<any>(tab ? tab : 'guardianNodes');
    const [myNodes, setMyNodes]: any = useState([]);

    const onChange = (key: string) => {
        localStorage.setItem('tabSlt', key);
        setActiveTab(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'guardianNodes',
            label: 'Guardian Nodes',
            children: <GuardianNodes />,
        },
        {
            key: 'my-nfts',
            label: 'My NFTs',
            children: 'Content of Tab Pane 2',
        },
        {
            key: 'daily_reward',
            label: 'Daily Reward',
            children: 'Content of Tab Pane 3',
        },
        {
            key: 'my-nodes',
            label: 'My Nodes',
            children: 'Content of Tab Pane 2',
        },
        
    ];

    const columnMyNode = [
        {
            title: <div className='text-white text-center'>Project Name</div>,
            dataIndex: 'node_tier_id',
            key: 'node_tier_id',
            render: (text:any, record:any) => {
				
				return (
					<div className="bg-blue p-5 w-fitcontent m-auto border-5">asdad</div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Quantity</div>,
            dataIndex: 'available_min',
            key: 'available_min',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-blue text-center'>
                        <div className="bg-blue p-5 m-auto w-fitcontent border-5">
                            {record?.available_min ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.available_min) : 0}
                        </div>
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Status</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-center'>
						<button className="btn-blue-border" disabled> {record?.available_min == 0 ? 'Minted' : 'Mint NFT'}</button>
					</div>
				)
			}
        },
    ];
    
  
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="delegate-page">
                        <div className="delegate-banner">
                            <Row gutter={30}>
                                <Col xs={24} sm={4}>
                                    <img className="img-full" src="../images/dele-banner-1.png" />
                                </Col>
                                <Col xs={24} sm={15}>
                                    <div className="text-white weight-600 font-30 mt-30">THE FIRST AIRDROP</div>
                                    <div className="text-white weight-500 font-25">250 <span className="text-green">$HELA</span></div>
                                    <div className="mt-15">
                                        <button className="btn-blue-border">Claim now</button>
                                    </div>  
                                </Col>
                                <Col xs={24} sm={5}>
                                    <img className="img-full flex-center" src="../images/dele-banner-3.png" />
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-btw mt-30">
                            <div className="text-white weight-600 font-30">
                                Guardian Nodes
                            </div>
                            <div className="text-white">
                                <a href="">How to operate your own Verifier?</a>
                            </div>
                        </div>
                        <div className="delegate-total mt-15">
                            <Row gutter={30}>
                                <Col xs={12} sm={5}>
                                    <div className="text-gray-light">License Key Holder</div>
                                    <div className="text-white mt-10">0x5E90...0ec7</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <div className="text-gray-light">My number of NFTs</div>
                                    <div className="text-white mt-10">0 (0 Delegated)</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <div className="text-gray-light">Status</div>
                                    <div className="text-white mt-10">___</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <div className="text-gray-light">Total Rewards</div>
                                    
                                    <div className="d-flex-left mt-10">
                                        <div className="text-white">
                                            0.00 <span className="text-gray-light">HELA</span>
                                        </div>
                                        <button disabled className="btn-blue-border">Claim</button>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4} className="flex-center">
                                    <button className="btn-blue-border">Delegated My License</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-30">
                            <div className="text-white font-20 weight-500">Projects</div>
                            <div className="tab-over mt-15">
                                <Table scroll={{y: 400, x:700 }} className="table-projects" bordered={false} rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'} dataSource={myNodes} columns={columnMyNode} pagination={false}/>
                            </div>
                        </div>
                        <div className="delegate-tabs mt-15">
                            <Tabs className="tabs-delegate" activeKey={activeTab} items={items} onChange={onChange} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default Delegate;


import { Col, message, Input, Layout, Row, Progress, Spin, Tag, Empty, Modal } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {UnorderedListOutlined, AppstoreOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";

const HomeDefault = () => {

    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState();
    const [isModalStaking , setIsModalStaking] = useState<any>(false);
    const handleShowStakeing = () => {
       
        setIsModalStaking(true);
    };

  
  return (
    <>
        <div className="app-page-default">
            <header className="header">
                <div className="main-header">
                    <div className="header-left">
                        <div className="box-img">
                            <Link to="/"><img src="../../../images/hela-node-logo.svg" alt="" /></Link>
                        </div>
                    </div>
                    <div className="header-center">
                        
                    </div>
                    <div className="header-right">
                        
                    </div>
                </div>
            </header>
            <div className="container">
                
                <div className="home-default">
                    <Row gutter={30}>
                        <Col xs={24} sm={8}>
                            <div className="text-center">
                                <img className="img-full" src="../images/shield-home.svg" />
                            </div>
                        </Col>
                        <Col xs={24} sm={16}>
                            <div className="home-des">
                                <div className="text-linegradiant">Phase 1 is sold out</div>
                                <div className="text-linegradiant">Looking to participate</div>
                                <p className="text-white font-18">Join the community to find out more on future sales.</p>
                                <div className="d-flex-left mt-15">
                                    <button onClick={handleShowStakeing} className="btn-border-blue text-blue p-12-15">Join the community here</button>
                                    <button onClick={()=> window.location.replace('/stake')} className="border-radius-30 btn-blue-border text-white p-10-15">Mint your NFTs HeLa</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="img-shadow">
                        <img  src="../images/shield-shadow.svg" />
                    </div>
                </div>
            
            </div>
            {/* <Footer /> */}
        </div>
        <Modal footer={false} className="modal-mint" title={<></>} open={isModalStaking} onOk={()=> setIsModalStaking(false)} onCancel={()=> setIsModalStaking(false)}>
            <div className="social-border">
                <div className="social-item">
                    <div className="">
                        <img  src="../images/x-w.svg" />
                    </div>
                    <div className="d-flex-left">
                        <span className="text-white"><a href="https://x.com/Hela_Network" target="_blank">X Social Media</a></span>
                        <span><img  src="../images/check.svg" /></span>
                    </div>
                </div>
                <div className="social-item">
                    <div className="">
                        <img  src="../images/tele-x.svg" />
                    </div>
                    <div className="d-flex-left">
                        <span className="text-white"><a href="https://t.me/hela_official" target="_blank">Telegram channel</a></span>
                        <span><img  src="../images/check.svg" /></span>
                    </div>
                </div>
                <div className="social-item">
                    <div className="">
                        <img  src="../images/dis-w.svg" />
                    </div>
                    <div className="d-flex-left">
                        <span className="text-white"><a href="https://discord.com/invite/helalabs" target="_blank">Discord Channel</a></span>
                        <span><img  src="../images/check.svg" /></span>
                    </div>
                </div>
            </div>
        </Modal>
    
    </>
  );
};
export default HomeDefault;

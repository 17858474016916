
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { CollapseProps } from 'antd';


const FAQ = () => {

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="text-white weight-400">1. What is HeLa Guardian Node?</span>,
            children: <>
                <p className="mt-0 text-white">A HeLa Guardian Node is a key part of the HeLa blockchain network. It ensures the network runs smoothly and securely by monitoring and enhancing its stability. Acting like a security guard, it enables DApps to function stably and securely on HeLa network. This creates a safer and more reliable environment for everyone, with rewards in HeLa tokens and potential perks from partner DApps.</p>
                <p className="mt-0 text-white">For more detailed technical information, please visit this <a target="_blank" href="https://docsend.com/view/ij3f3wxvtgx3quke">link</a>.</p>
            </>,
        },
        {
            key: '2',
            label: <span className="text-white weight-400">2. What benefits do I get from buying a HeLa Guardian Node?</span>,
            children: <>
                <p className="mt-0 text-white">By running a HeLa Guardian Node, you directly contribute to the ecosystem by enhancing network security and supporting its growth. HeLa node owners will receive token rewards, including airdrops during listing events, and enjoy additional benefits from future collaborations with HeLa and its partners.</p>
            </>,
        },
        {
            key: '3',
            label: <span className="text-white weight-400">3. How do I get started and how can I set up my node?</span>,
            children: <>
                <p className="mt-0 text-white">Follow this guide for a step-by-step guide to help you get started: —-----</p>
            </>,
        },
        {
            key: '4',
            label: <span className="text-white weight-400">4. Where can I buy Node?</span>,
            children: <>
                <p className="mt-0 text-white">You can buy a HeLa Guardian Node through the HeLa Labs node sales page. Check it here: </p>
            </>,
        },
        {
            key: '5',
            label: <span className="text-white weight-400">5. What unique features does the HeLa node offer compared to others?</span>,
            children: <>
                <ul className="text-white mt-0">
                    <li><strong>First Mover Advantage:</strong> Earn a part of HeLa's total supply.</li>
                    <li><strong>NFT License:</strong> Get an NFT for node ownership.</li>
                    <li><strong>Extra Earnings:</strong> Stake NFTs to earn tokens from partnered projects.</li>
                    <li><strong>One Node for All:</strong> Exclusive platform for staking future NFTs.</li>
                    <li><strong>Earn & Stake:</strong> Get more HeLa tokens for rewards or convert to stablecoins.</li>
                    <li><strong>Delegation Fees:</strong> Earn from others staking their NFTs to your node.</li>
                    <li><strong>Potential Airdrop Incentives:</strong> Potentially earn fees from future NFTs staked on the nodes.</li>
                </ul>
            </>,
        },
        {
            key: '6',
            label: <span className="text-white weight-400">6. Is it guaranteed for Potential rewards?</span>,
            children: <>
                <p className="mt-0 text-white">There is a fixed airdrop, but other perks depend on HeLa securing more partnerships. Please refer to the node deck for detailed ROI information for each tier.</p>
            </>,
        },
        {
            key: '7',
            label: <span className="text-white weight-400">7. How can owning a HeLa Guardian Node financially benefit me?</span>,
            children: <>
                <ul className="text-white mt-0">
                    <li><strong>Earn Rewards:</strong> Get HeLa Chain Rewards for network contribution.</li>
                    <li><strong>Early Access:</strong> Access new NFT launches for project tokens.</li>
                    <li><strong>Extra Earnings:</strong> Stake HeLa tokens for more rewards or convert to stablecoins.</li>
                    <li><strong>Delegation Fees:</strong> Earn from others staking their NFTs to your node.</li>
                    <li><strong>Airdrop Incentives:</strong> Earn fees from future NFTs staked on the nodes.</li>

                </ul>
            </>,
        },
    ];

    const itemsPricing: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="text-white weight-400">1. What is the initial price for a HeLa node?</span>,
            children: <>
                <p className="mt-0 text-white">The initial price is 0.100 ETH.</p>
            </>,
        },
        {
            key: '2',
            label: <span className="text-white weight-400">2. How many Guardian Nodes are available in each tier?</span>,
            children: <>
                <p className="mt-0 text-white">There are 2,500 Guardian Nodes available in each tier.</p>
            </>,
        },
        {
            key: '3',
            label: <span className="text-white weight-400">3. What is the total number of Guardian Nodes available for sale?</span>,
            children: <>
                <p className="mt-0 text-white">A total of 50,000 Guardian Nodes are available for sale across 20 tiers.</p>
            </>,
        },
        {
            key: '4',
            label: <span className="text-white weight-400">4. How does the tiered pricing work for the HeLa nodes?</span>,
            children: <>
                <p className="mt-0 text-white">The price increases by 15% per tier from Tier 1 to Tier 10 and by 10% per tier from Tier 11 to Tier 20.</p>
            </>,
        },
        {
            key: '5',
            label: <span className="text-white weight-400">5. Through which network will the node sale be conducted?</span>,
            children: <>
                <p className="mt-0 text-white">The sale will be conducted on Arbitrum network via a public sale.</p>
            </>,
        },
        {
            key: '6',
            label: <span className="text-white weight-400">6. Can you buy fractions of a node?</span>,
            children: <>
                <p className="mt-0 text-white">No, nodes will be sold in whole units only.</p>
            </>,
        },
        {
            key: '7',
            label: <span className="text-white weight-400">7. When can nodes be transferred after purchase?</span>,
            children: <>
                <p className="mt-0 text-white">Nodes can be transferred one year after the minting day.</p>
            </>,
        },
        {
            key: '8',
            label: <span className="text-white weight-400">8. What is the maximum number of nodes that each wallet address can purchase per tier?</span>,
            children: <>
                <p className="mt-0 text-white">Each wallet address is limited to 10 nodes per tier.</p>
            </>,
        },
        {
            key: '9',
            label: <span className="text-white weight-400">9. Will there be a whitelisting process for any tiers?</span>,
            children: <>
                <p className="mt-0 text-white">Yes, there will be a whitelisting process for the first 10 tiers.</p>
            </>,
        },
        {
            key: '10',
            label: <span className="text-white weight-400">10. What is the difference per each tier?</span>,
            children: <>
                <p className="mt-0 text-white">Each tier has a different price and potential return. </p>
            </>,
        },
        {
            key: '11',
            label: <span className="text-white weight-400">11. What will I receive alongside the token node reward?</span>,
            children: <>
                <p className="mt-0 text-white">You will receive a total of 720 HeLa tokens per node. Out of these 720 tokens, 288 will be airdropped at the TGE. The remaining tokens will be vested over a period of 4 years. Besides HeLa tokens, node owners are on the priority list to receive perks from all partnerships and campaigns within the HeLa ecosystem.</p>
            </>,
        },
        {
            key: '12',
            label: <span className="text-white weight-400">12. What is the price of each node at Tier 1 in USD?</span>,
            children: <>
                <p className="mt-0 text-white">The price is 0.1 ETH, equivalent to 330 USD (given that 1 ETH = 3300$). Please note that the sale is conducted in ETH, and the USD price is just for reference.</p>
            </>,
        },
        {
            key: '13',
            label: <span className="text-white weight-400">13. How many token rewards does each node receive?</span>,
            children: <>
                <p className="mt-0 text-white">Each node receives 720 HeLa token rewards. Out of these 720 tokens, 288 will be airdropped at the TGE. The remaining tokens will be vested over a period of 4 years.</p>
            </>,
        },
    ];

    const itemsReferral: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="text-white weight-400">1. Can I get a discount if I share my purchase with friends?</span>,
            children: <>
                <p className="mt-0 text-white">Yes, you can! If you share your referral link with friends, they get a 5% discount, and you earn a 5% commission on their purchases.</p>
            </>,
        },
        {
            key: '2',
            label: <span className="text-white weight-400">2. What is the rebate percentage for buyers?</span>,
            children: <>
                <p className="mt-0 text-white">Buyers in the Individual Referral Scheme get a 5% discount.</p>
            </>,
        },
        {
            key: '3',
            label: <span className="text-white weight-400">3. How and when will I receive my rebate and commission?</span>,
            children: <>
                <p className="mt-0 text-white">Your rebate and commission will be processed two weeks after the sale completion. Keep in mind, commissions might require passing KYC (Know Your Customer).</p>
            </>,
        },
        {
            key: '4',
            label: <span className="text-white weight-400">4. What if I want to refer to a lot of people?</span>,
            children: <>
                <p className="mt-0 text-white">If you have a large community or influence, you can apply for the Partner Referral Scheme and earn a commission ranging from 5% to 10%, depending on the amount sold. </p>
            </>,
        },
        {
            key: '5',
            label: <span className="text-white weight-400">5. Where do I apply for the Partner Referral Scheme?</span>,
            children: <>
                <p className="mt-0 text-white">You can apply for the Partner Referral Scheme using this link: Apply here.</p>
            </>,
        }
    ];
    
  
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="faq-page">
                        <div className="text-center font-25 weight-500 text-white mb-15">FAQs about HeLa Node Sale</div>
                        <Divider className="mb-0 text-center" orientation="left"><div className="text-lightblue font-20 weight-500 text-center">Node Sale Introduction</div></Divider>
                        <Collapse items={items} expandIcon={({ isActive }) => ''} bordered={false}/>

                        <Divider className="mb-0 text-center" orientation="left"><div className="text-lightblue font-20 weight-500 text-center">Node Sale Pricing</div></Divider>
                        <Collapse items={itemsPricing} expandIcon={({ isActive }) => ''} bordered={false}/>

                        <Divider className="mb-0 text-center" orientation="left"><div className="text-lightblue font-20 weight-500 text-center">Referral</div></Divider>
                        <Collapse items={itemsReferral} expandIcon={({ isActive }) => ''} bordered={false}/>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default FAQ;
